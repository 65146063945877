.alert-popup {
  border: 1px solid #fff; /* 테두리 */
  
}
.alert-popup-confirm-button {
  border: 1px solid #fff !important; /* 테두리 */
  background-color: #000 !important; /* 배경색 */
}

.btn-init-register{
  width: 100%;
  background: transparent;
  border: 0;
  margin-bottom: 2em;
  padding: 0;
}
