/* html, body{
  background-color: #B4B4B4 !important;
}
.contents {
  background-color: #B4B4B4 !important;
} */

.bg-b4b4b4 {
  background-color: #B4B4B4 !important;
}

.no-color {
  color: transparent !important;
  background: transparent !important;
}
.register-form {
  margin-left: 1.4em;
  margin-right: 1.4em;
  margin-bottom: 4em;
  position: relative;
}
.register-form-logo{
  width: 100%;
  text-align: center;
  margin-bottom: 4em;
  margin-top: 1.5em;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;  
}

.register-form-subject {
  font-size: 0.85em;
  text-align: left;
  /* height: 3em; */
  display: flex;
  justify-content: space-between; 
  margin-top: 1.5em;

}

.register-form-value img {
  height: 1.8em;
  margin-bottom: 0.4em;
}

.register-form-value {
  /* margin-right: 2em; */
  width: 76%;
}

.white-text {
  color: #fff !important;
}

.label{
  color: #727272;
  margin-top: 0.325em;
  line-height: 1.6em;
}

/* hr {
  border: 0;
  height: 1px;
  background: transparent;
  margin-top: 1.5em;
} */

.register-form-value input[type="text"]
, .register-form-value input[type="tel"]
, .register-form-value input[type="email"] {
  border: 0;
  width: 100%;
  font-size: 2em;
  /* height: 3em; */
  color: #fff;
}

.register-fields {
  padding-bottom: 1.3em;
  border-bottom: #565656 solid 1px;
  margin-bottom: 4em;
}

.register-form-value input[type="text"]::placeholder {
  font-size: 1em;
}
.disclaimer .register-form-value{
  width: 90%;
  word-break: keep-all;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.name-info{
  text-align: left;
  margin-top: 1em;
  margin-left: 1.8em;
  margin-bottom: 5em;
  font-size: 0.85em;
}

.agree-submit {
  /* padding-bottom: 1em; */
  margin-top: 3em !important;
}

.disclaimer-text {
  line-height: 1.4em !important;
}

.bg-video-register {
  /* max-width: 100vw; */
  min-width: 100vw;
  min-height: 100vh;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.title {
  color: #fff !important;
  font-size: 1.4em;
}

.btn-counter {
  border: 0;
  font-size: 1.6em;
  background-color: transparent;
  padding: 0;
  position:relative;
  height: 1em;
}

.btn-counter img {
  /* height: 2em; */
  margin: 0;
  vertical-align: middle;
  padding-bottom: 0 !important;
  margin-bottom: -1em !important;
  position:absolute;
  top: 50%;
  left:50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
