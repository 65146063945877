video {
  pointer-events: none;
  /* min-height: 400px; */
}
.bottom-area {
  margin-left: 1em;
  margin-right: 1em;
}

.verify-code-form {
  text-align: center;
}
.verify-code-form input[type="text"] {
  text-align: center;
  /* font-size: 1.3em; */
  font-size: 1em;
  color: #565656;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 1em;
    /* padding: 5px; */
  /* padding-top: 5px; */
  padding-bottom: 5px;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;  
  
}
.verify-code-form  input[type="text"]::placeholder {
  color: #727272;
}

/* sponsor-footer */
.sponsor-footer {
  /* border: 1px solid #fff; */
  margin-top: 1em;
  /* text-align: center; */
  color: #565656;
  display: flex; /* Flexbox를 사용하여 요소들을 한 줄로 정렬합니다. */
  justify-content: space-between; /* 요소들을 양쪽 끝에 정렬합니다. */
  align-items: center; /* 세로 가운데 정렬합니다. */
  margin-top: 2em;
  margin-bottom: 4em;
  flex-flow: row wrap;
  align-content: space-between;
  justify-content: space-between;
  
}

.sponsor-footer {
  margin-top: 1.8em;
}
.sponsor-footer .sponsor, .sponsor-footer .footer {
  width: 50%;
  
}

.sponsor-footer .sponsor {
  display: flex;
}


@media (max-width: 767px){
	.sponsor-footer .sponsor {
    width: 49%;
    margin-top: 1em;
  }
}


.sponsor-footer .sponsor img{
  height: 1.8em;
  margin-left: 0;
  display: flex;
  
}

.sponsor-footer .sponsor h2{
  font-size: 0.75em;
  margin-top: 0.325em;
  margin-bottom: 0.3em;
  text-align: right;
  margin-right: 1em;
}

.sponsor-footer .footer {
  display: flex;
  justify-content: flex-end; 
}

.sponsor-footer .footer img{
  height: 1.8em;
  margin-right: 1em;
  /* margin-top: 1em; */
}

.sponsor-footer .sponsor img{
  height: 1.3em;
  margin-right: 1em;
  /* margin-top: 1em; */
}


hr {
  border: none;
  height: 0.6px;
  background: #565656;
  margin-top: 6em;
}


.special-mode {
  filter: drop-shadow(5px 5px 0 red) hue-rotate(180deg)
    drop-shadow(5px 5px 0 red);
}
